import { JsonObject } from '../helpers';

type ModelLifecycle = {
  status: string;
};

type ProviderModelProperties = {
  customizationsSupported: string[];
  inferenceTypesSupported: string[];
  inputModalities: string[];
  outputModalities: string[];
  modelArn: string;
  modelLifecycle: ModelLifecycle;
  modelName: string;
  providerName: string;
  responseStreamingSupported: boolean;
};

const ProviderModelNameMap: Record<string, string> = {
  google: 'Gemini',
  'google-ai-studio': 'Gemini',
  openai: 'OpenAI',
  'azure-openai': 'OpenAI',
};

/*
 * Corresponds to the response given by the org service /orgs/private-models/available endpoint
 * These are the models that can be used in a PrivateModelModel which in turn are
 * assigned to policies
 */
export class AvailablePrivateModel {
  orgId: string; // Required, matches pattern ^[a-f\d]{24}$
  name: string; // Required, max length 255
  provider: string; // Required, max length 255
  service: string; // Required, max length 255
  modelId: string; // Required
  providerModelProperties: ProviderModelProperties; // Required
  surepathSupported: boolean; // Required
  modelProviderName: string;
  inputModalities: string[];
  outputModalities: string[];

  constructor(props: JsonObject = {}) {
    const data = props as Record<keyof AvailablePrivateModel, unknown>;

    this.orgId = (data.orgId as string) || '';
    this.name = (data.name as string) || '';
    this.provider = (data.provider as string) || '';
    this.modelId = (data.modelId as string) || '';
    this.service = (data.service as string) || '';
    this.surepathSupported = (data.surepathSupported as boolean) || false;
    this.modelProviderName = (data.modelProviderName as string) || '';

    this.providerModelProperties = (data.providerModelProperties as ProviderModelProperties) || {
      customizationsSupported: [],
      inferenceTypesSupported: [],
      inputModalities: ['text'],
      outputModalities: ['text'],
      modelArn: '',
      modelLifecycle: {
        status: '',
      },
      modelName: '',
      providerName: '',
      responseStreamingSupported: false,
    };

    // legacy schema support
    if (!this.modelProviderName && this.providerModelProperties.providerName) {
      this.modelProviderName = this.providerModelProperties.providerName;
    }

    // not supplied? attempt provider lookup
    if (!this.modelProviderName) {
      this.modelProviderName = ProviderModelNameMap[this.provider] || '';
    }

    this.inputModalities = (
      (data.inputModalities as string[]) ||
      this.providerModelProperties.inputModalities || ['text']
    ).map((value) => String(value).toLowerCase());
    this.outputModalities = (
      (data.outputModalities as string[]) ||
      this.providerModelProperties.outputModalities || ['text']
    ).map((value) => String(value).toLowerCase());
  }

  get iconId(): string {
    return String(this.modelId).toLowerCase().split('-').shift() || '';
  }
}
