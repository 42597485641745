import Drawer, { DrawerProps } from '@/components/drawers';
import DrawerContent from '@/components/drawers/content';
import DrawerHeader from '@/components/drawers/header';
import DrawerButtons from '@/components/form/drawer-buttons';
import InputControl from '@/components/form/input-control';
import useFormHandle from '@/hooks/use-form-handle.hook';
import useModal from '@/hooks/use-modal.hook';
import useQueryHelper from '@/hooks/use-query-helper';
import useToast from '@/hooks/use-toast.hook';
import { UiOption, ViewState } from '@/lib/helpers';
import { PrivateModelModel } from '@/lib/models/private-model.model';
import {
  createAwsPrivateModel,
  deletePrivateModel,
  getAvailablePrivateModels,
  getModelUsage,
  getPrivateModelById,
  ModelUsage,
  ModelUsageMap,
  patchPrivateModel,
} from '@/lib/services/private-model.service';
import { Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import DrawerToolbar from '../toolbar';
import { AwsConnectorModel } from '@/lib/models/connector/aws-connector.model';
import { ConnectorModel } from '@/lib/models/connector/connector.model';
import { AvailablePrivateModel } from '@/lib/models/available-private-model.model';
import { QueryKey } from '@/lib/query-client';
import { Form } from '@/components/form';
import { getAllConnectors } from '@/lib/services/connector.service';
import InlineToast from '@/components/toasts/inline';
import PrivateModelReadonlyView from './readonly-view';
import useFeature from '@/hooks/use-feature';

interface Props extends DrawerProps {
  id: string;
}
const formModel = new PrivateModelModel();

const getModelOpts = (availableModels: AvailablePrivateModel[]): UiOption[] => {
  if (!availableModels?.length) {
    return [];
  }

  const makeModelOpt = (model: AvailablePrivateModel, disabled = false): UiOption => ({
    value: model.modelId,
    label: `${model.name} (${model.modelId})`,
    disabled,
  });

  const supportedModelOpts = availableModels
    .filter(({ surepathSupported }) => surepathSupported)
    .map((model) => makeModelOpt(model));
  // const otherModelOpts = availableModels
  //   .filter(({ surepathSupported }) => !surepathSupported)
  //   .map((model) => makeModelOpt(model, true));

  return [
    { label: 'Supported Models', value: 'supported', meta: { header: true } },
    ...supportedModelOpts,
    // { label: 'Unsupported Models', value: 'unsupported', meta: { header: true } },
    // ...otherModelOpts,
  ];
};

export const PrivateModelDrawer: FC<Props> = ({ id, open, onClose, onChange, className }) => {
  const [modelId, setModelId] = useState(id || null);
  const isNew = !modelId;
  const [mode, setMode] = useState<ViewState>(isNew ? 'edit' : 'view');
  const [connectorId, setConnectorId] = useState('');
  const { toast, errorToast } = useToast();
  const { openModal } = useModal();

  const { canChangePrivateModels } = useFeature();

  const connectorQuery = useQuery<ConnectorModel[]>('pm-connectors-list', async () => {
    const { rows: connectors } = await getAllConnectors();
    return connectors.filter(
      ({ supportedFeatures, type }) => type === 'aws' && supportedFeatures.privateModels
    );
  });

  const { data: availableConnectors = [] } = connectorQuery;
  const { showLoader: connectorsLoading } = useQueryHelper(connectorQuery);

  const availableModelsQuery = useQuery<AvailablePrivateModel[]>(
    ['pm-available-models-list', connectorId],
    async () => {
      if (!connectorId) {
        return [];
      }

      const { rows: availableModels } = await getAvailablePrivateModels([connectorId]);

      return availableModels;
    },
    { enabled: !!connectorId }
  );

  const { data: availableModels = [], refetch: refetchAvailableModels } = availableModelsQuery;
  const { showLoader: availableModelsLoading } = useQueryHelper(availableModelsQuery);

  const query = useQuery<[PrivateModelModel | null, ModelUsageMap]>(
    [QueryKey.PrivateModelViewMeta, modelId],
    async () => Promise.all([getPrivateModelById(modelId!), getModelUsage([modelId!])]),
    {
      enabled: !!modelId,
    }
  );

  const { showLoader: modelLoading } = useQueryHelper(query);
  const { data, refetch } = query;

  const [modelData, usageData] = data || [];
  const privateModel = modelData || new PrivateModelModel();
  const modelUsage: ModelUsage[] = usageData?.get(modelId!) || [];

  const formHandle = useFormHandle({
    initialValues: formModel,
    validationSchema: formModel.schema,
    validateOnChange: !isNew,
    onSubmit: async (values) => {
      if (!modelId) {
        const chosenModel = availableModels.find(({ modelId }) => modelId === values.modelId);
        const chosenConnector = availableConnectors.find(({ id }) => id === values.connectorId);

        if (!chosenModel || !chosenConnector) {
          errorToast('Invalid model or connector');
          return;
        }

        const {
          inserted,
          id: newModelId,
          error,
        } = await createAwsPrivateModel(values, chosenConnector as AwsConnectorModel, chosenModel);

        if (!inserted || error) {
          errorToast(error || 'The private model was not created');
          return;
        }

        toast('The private model was created');
        setModelId(newModelId!);
        onChange && onChange();
        setMode('view');
        return;
      }

      const { updated, error } = await patchPrivateModel(values.id!, values.name);

      if (updated) {
        toast('The private model was updated');
        refetch();
        onChange && onChange();
        setMode('view');
        return;
      }

      errorToast(error || 'The private model was not updated');
    },
  });

  const { handleSubmit, canSubmit, isSubmitting, loadData } = formHandle;
  const inWriteMode = mode === 'edit';

  const handleCancel = () => {
    if (isNew) {
      onClose();
      return;
    }
    setMode('view');
    loadData(privateModel);
  };

  const handleEdit = () => {
    setMode('edit');
  };

  const handleClose = () => {
    onClose();
  };

  const handleDelete = () => {
    openModal('confirm', {
      title: 'Confirm Delete',
      content: `Are you sure you want to delete ${privateModel.name}?`,
      onClose: (confirm: boolean) => {
        if (confirm) {
          deletePrivateModel(modelId!).then(({ deleted, error }) => {
            if (deleted) {
              setModelId('');
              onClose();
              toast('The model was deleted');
              onChange && onChange();
              return;
            }

            errorToast(error || 'The model was not deleted');
          });
        }
      },
    });
  };

  const handleChangeConnector = (value: string) => {
    setConnectorId(value);
    refetchAvailableModels();
  };

  const modelConnector = availableConnectors.find(({ id }) => id === connectorId);
  const hasConnector = !!modelConnector;
  const hasUsage = !!modelUsage.length;
  const canDelete = !hasUsage;
  const showChildren = !connectorsLoading && !modelLoading;
  const showModelWarning = hasConnector && !availableModelsLoading && !availableModels.length;

  const modelOpts: UiOption[] = hasConnector ? getModelOpts(availableModels) : [];

  const connectorOpts: UiOption[] = availableConnectors.map(({ id, name, status }) => ({
    label: name,
    value: id!,
    disabled: status !== 'active',
  }));

  useEffect(() => {
    if (data) {
      const [model] = data;
      setConnectorId(model?.connectorId || '');
    }
  }, [data]);

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      className={className}
      query={query}
      loading={connectorsLoading || modelLoading}
    >
      <DrawerHeader onClose={handleClose}>
        {isNew ? 'Add Private Model' : privateModel.name}
      </DrawerHeader>

      <DrawerContent>
        {!isNew && canChangePrivateModels && (
          <DrawerToolbar
            onEdit={handleEdit}
            onDelete={handleDelete}
            canEdit={!inWriteMode}
            canDelete={canDelete}
            deleteTooltip={!canDelete ? 'You cannot delete a model that is in use' : ''}
          />
        )}
        <Stack>
          {!inWriteMode && showChildren && (
            <PrivateModelReadonlyView
              model={privateModel}
              connector={modelConnector}
              usage={modelUsage}
            />
          )}

          {inWriteMode && showChildren && (
            <Form formHandle={formHandle} query={query}>
              <InputControl name="name" label="Name" formHandle={formHandle} autoFocus />

              <InputControl
                name="connectorId"
                label="Connector"
                formHandle={formHandle}
                onChange={handleChangeConnector}
                type="select"
                options={connectorOpts}
                readonly={!isNew}
              />

              <InputControl
                name="modelId"
                label="Model"
                formHandle={formHandle}
                type="select"
                options={modelOpts}
                disabled={!hasConnector || showModelWarning}
                readonly={!isNew}
              />

              {showModelWarning && (
                <InlineToast
                  hideIcon
                  level="warning"
                  message="No models available for this connector"
                />
              )}

              <DrawerButtons
                submit
                cancel
                isNew={isNew}
                canSubmit={canSubmit}
                canCancel={!isSubmitting}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
              />
            </Form>
          )}
        </Stack>
      </DrawerContent>
    </Drawer>
  );
};

export default PrivateModelDrawer;
